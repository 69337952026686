import 'flatpickr/dist/themes/material_red.css';

import Button from '@atoms/Button/Button';
import useTranslations from '@hooks/useTranslations';
import { RangeFilter } from '@type-declarations/filter';
import clsx from 'clsx';
import { differenceInDays, endOfDay } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-flatpickr';

import styles from './DateRangePicker.module.scss';

interface Props {
  onChange: (dates: RangeFilter) => void;
  className?: string;
  initialRange: RangeFilter | null;
}

export default function DateRangePicker({
  onChange,
  className,
  initialRange,
}: Props) {
  const { chooseDate: chooseDateTranslation } = useTranslations();

  const [selectedDates, setSelectedDates] = useState<RangeFilter | null>(
    initialRange
  );

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSelectedDates(initialRange);
  }, [initialRange]);

  const handleDateChange = (dates: Date[]) => {
    if (dates.length !== 2) return;

    const firstDate = dates[0].getTime();
    const secondDate = endOfDay(dates[1]).getTime();

    setSelectedDates([firstDate, secondDate]);
    onChange([firstDate, secondDate]);
  };

  const ref = useRef<DatePicker | null>(null);

  const toggle = () => {
    if (isOpen) {
      ref.current?.flatpickr.close();
    } else {
      ref.current?.flatpickr.open();
    }

    setIsOpen(!isOpen);
  };

  return (
    <div className={clsx(styles.wrapper, className)}>
      <Button
        className={styles.button}
        label={chooseDateTranslation}
        variant="white"
        iconLeft="CALENDAR"
        icon="NAV_ARROW_RIGHT"
        onClick={toggle}
        count={
          selectedDates?.length &&
          differenceInDays(selectedDates[1], selectedDates[0]) + 1
        }
      />

      <DatePicker
        className={styles.hidden}
        value={selectedDates || undefined}
        ref={ref}
        onChange={handleDateChange}
        onClose={() => setIsOpen(false)}
        options={{
          mode: 'range',
          dateFormat: 'y-m-d',
          altInput: true,
          altFormat: 'F j, y',
          minDate: 'today',
        }}
      />
    </div>
  );
}
